import React from 'react';
import { Layout, Seo } from 'containers';

const NotFoundPage = () => (
	<Layout type='page'>
		<main className='main'>
			<Seo title='Page not found' />
			<h1 className='heading--1'>This page does not exist</h1>
			<p>Looks like this page had to be urgently extracted for your browsing safety.</p>
			<p>Need emergency dental care?</p>
			<p><a href="https://www.authoritydental.org/emergency-dentist-near-me">Use Authority Dental to find 24-hour dentists near you. </a></p>
			<p>Our dental team is standing by. Contact us 24 hours a day, including weekends, nights, and after hours.</p>
		</main>
	</Layout>
);

export default NotFoundPage;
